<template>
  <div>
    <div id="s-html5-qrcode-scanner"></div>
    <div v-if="cameraErrorMessage" class="text-center" style="margin-top: 30px;">
      {{ cameraErrorMessage }}
      <SharedButton class="s-btn-bg-primary mt-3" @click="reloadPage">
        重新整理頁面
      </SharedButton>

      <SharedButton v-if="showRetryButton" class="s-btn-bg-primary mt-3" @click="retryCamera">
        重新授權相機
      </SharedButton>
    </div>
  </div>
</template>

<script>
import { Html5Qrcode } from 'html5-qrcode'
import SharedButton from "@/components/Page/Liff/Shared/Button";
import liff from "@line/liff";

export default {
  components: {
    SharedButton,
  },
  props: {
    qrbox: {
      type: Object,
      default: () => ({ width: 250, height: 250 })
    },
    fps: {
      type: Number,
      default: 120
    },
  },
  data: () => ({
    html5QrCode: null,
    errorCount:0,
    cameraErrorMessage: null,
    showRetryButton: false,
  }),
  mounted() {
    console.log("QRCodeScanner.vue mounted");
    this.initializeScanner()
  },
  beforeUnmount() {
    console.log("QRCodeScanner.vue beforeUnmount");
    this.stopScanner()
  },
  methods: {
    initializeScanner() {
      this.html5QrCode = new Html5Qrcode("s-html5-qrcode-scanner");

      const config = {
        fps: this.fps,
        qrbox: this.qrbox,
        aspectRatio: 1.0,
        disableFlip: false, // 允許鏡像翻轉，可能有助於某些設備
        experimentalFeatures: {
          useBarCodeDetectorIfSupported: true // 使用瀏覽器的原生條碼檢測器（如果支持）
        },
        focusMode: 'continuous'
      };

      this.html5QrCode.start(
        {
          facingMode: "environment"
        },
        config,
        this.onScanSuccess,
        this.onScanFailure
      ).catch(async (err) => {
        let errorMessage = "啟動掃描器失敗";
        if (err.includes("NotAllowedError")) {
          errorMessage = "相機使用權限被拒絕。請允許相機權限後重試。";
        } else if (err.includes("NotReadableError")) {
          errorMessage = "無法讀取相機。請檢查相機是否被其他應用佔用。";
        } else if (err.includes("NotFoundError")) {
          errorMessage = "未找到相機設備。請確保相機已正確連接。";
        } else if (err.includes("OverconstrainedError")) {
          errorMessage = "無法滿足相機約束條件。請檢查相機設置。";
        } else if (err.includes("SecurityError")) {
          errorMessage = "安全錯誤。請檢查瀏覽器的安全設置。";
        } else if (err.includes("AbortError")) {
          errorMessage = "相機啟動被中止。請重試。";
        }

        if (liff.isInClient()) {
          if (!err.includes("NotAllowedError")) {
            let liffResult = await this.openLiffScanner();

            if (!liffResult) {
              this.cameraErrorMessage = errorMessage;
              this.showRetryButton = true;
            }
          }
        } else {
          this.showRetryButton = true;
        }
      });
    },
    onScanSuccess(decodedText, decodedResult) {
      console.log(`掃描結果: ${decodedText}`, decodedResult);
      this.$emit('onScan', decodedText);
      this.stopScanner();
    },
    onScanFailure(error) {
      if (this.errorCount < 10) {
        console.warn(`二維碼掃描失敗 (${this.errorCount + 1}/10): ${error}`);
        this.errorCount++;
      } else if (this.errorCount === 10) {
        console.warn("二維碼掃描持續失敗，停止日誌輸出以避免刷屏");
        this.errorCount++;
      }
    },
    stopScanner() {
      if (this.html5QrCode && this.html5QrCode.isScanning) {
        this.html5QrCode.stop().catch(err => {
          console.error("停止掃描器失敗:", err);
        });
      }
    },
    reloadPage() {
      window.location.reload();
    },
    async openLiffScanner() {
      try {
        // this.$swal('提醒', '已改為啟用 LIFF 掃瞄器', 'warning');
        let result = await liff.scanCodeV2();
        this.$emit('onScan', result.value);
        return true;
      } catch (error) {
        console.error("Liff scan failed", error);
        this.$swal('錯誤', 'LIFF 掃瞄器啟動失敗', 'error');
        return false;
      }
    },
    retryCamera() {
      navigator.permissions.query({name: 'camera'})
        .then((permissionObj) => {
          console.log(permissionObj.state);
          this.initializeScanner();
          this.showRetryButton = false;
        })
        .catch((error) => {
          console.log('Got error :', error);
        })
    }
  }
}
</script>

<style scoped>
#s-html5-qrcode-scanner {
  width: 100%;
  max-width: 500px;
  margin: 0 auto;
}
</style>
